export enum AppCookie {
  LoginChallenge = 'login-challenge',
  TokenAdmin = 'alt-token-admin',
  TokenPartner = 'alt-token-partner',
  TokenCustomer = 'alt-token-customer',
  RefreshAdmin = 'alt-refresh-admin',
  RefreshPartner = 'alt-refresh-partner',
  RefreshCustomer = 'alt-refresh-customer',
  RefreshLock = 'refresh-lock',
  PartnerId = 'X-Partner-Id',
  CustomerId = 'X-Customer-Id',
  PartnerIdAuth = 'partner-id-auth',
  Impersonating = 'impersonating',
  ClosedBanner = 'closed-banner',
  LoggedAsGuest = 'logged-as-guest'
}

const HOUR = 60 * 60
const MONTH = 30 * 24 * HOUR
const DEFAULT_COOKIE_MAX_AGE = 6 * MONTH

const cookieMaxAge: Record<AppCookie, number> = {
  // set expiry to 2 hours (2x Ory default)
  [AppCookie.LoginChallenge]: 2 * HOUR,
  [AppCookie.TokenAdmin]: 2 * HOUR,
  [AppCookie.TokenPartner]: 2 * HOUR,
  [AppCookie.TokenCustomer]: 2 * HOUR,
  // set expiry to 2 months (2x Ory default)
  [AppCookie.RefreshAdmin]: 2 * MONTH,
  [AppCookie.RefreshPartner]: 2 * MONTH,
  [AppCookie.RefreshCustomer]: 2 * MONTH,
  // set expiry to 10 seconds (request should be max 100ms, just to be safe if offline)
  [AppCookie.RefreshLock]: 10,
  // set expiry to default (6 months)
  [AppCookie.PartnerId]: DEFAULT_COOKIE_MAX_AGE,
  [AppCookie.CustomerId]: DEFAULT_COOKIE_MAX_AGE,
  [AppCookie.PartnerIdAuth]: DEFAULT_COOKIE_MAX_AGE,
  [AppCookie.Impersonating]: DEFAULT_COOKIE_MAX_AGE,
  [AppCookie.ClosedBanner]: DEFAULT_COOKIE_MAX_AGE,
  [AppCookie.LoggedAsGuest]: HOUR
}

const calculateExpires = (seconds: number) =>
  new Date(Date.now() + seconds * 1000)

export const getCookieExpires = (appCookie: AppCookie): Date => {
  const maxAge = cookieMaxAge[appCookie] || DEFAULT_COOKIE_MAX_AGE

  return calculateExpires(maxAge)
}
