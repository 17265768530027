import { keyframes } from '@emotion/react'
import { createStyles } from 'utils/css'

import { IDots } from './Loading.types'

const Dots = ({ color = 'grey25', dotSize = 1, duration = 550 }: IDots) => {
  const root = () =>
    createStyles(({ size }) => ({
      display: 'inline-block',
      height: size(dotSize),
      textAlign: 'center'
    }))
  const dot = () =>
    createStyles(({ colors, size }) => ({
      position: 'relative',
      display: 'inline-block',
      verticalAlign: 'top',
      margin: `auto ${size(dotSize / 3)}`,
      width: size(dotSize),
      height: size(dotSize),
      backgroundColor: colors[color],
      lineHeight: 0,
      borderRadius: '50%',
      transformOrigin: 'bottom',
      animation: `${dots} infinite alternate`,
      animationDuration: `${duration}ms`,
      '&:nth-of-type(2)': {
        animationDelay: '200ms'
      },

      '&:nth-of-type(3)': {
        animationDelay: '400ms'
      }
    }))
  const dots = keyframes`
      0% {
        transform: scale(0.25)
      }
      100% {
        transform: scale(1)
      }
    `

  return (
    <div css={root}>
      <span css={dot} />
      <span css={dot} />
      <span css={dot} />
    </div>
  )
}

export default Dots
